#app {
  max-width: 850px;
  margin: 0 auto;
}

nav {
  padding: 20px 0 20px 0;
}

.nav-item {
  text-decoration: none;
}

.notification {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 250px;
}
